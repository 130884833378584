import { Commission } from "@lib/gql/graphql";
import { Cpa } from "@lib/types/campaign";
import {
    CommissionFormatter,
    CpaFormatter,
    commissionToString,
    cpaToString,
    ensurePercentage,
    isMoney,
    isPercentage,
} from "@utils/currency";
import React from "react";

const useDefaultUntilFirstRender = <T>(serverValue: T, clientValue: T) => {
    const [value, setValue] = React.useState(() => serverValue);

    React.useEffect(() => {
        setValue(() => clientValue);
    }, [clientValue]);

    return value;
};

const naiveFormatter = (commission: Cpa | Commission) => {
    if (isMoney(commission))
        return `${commission.amount} ${commission.currency}`;
    if (isPercentage(commission))
        return `${ensurePercentage(commission.percentage) * 100}%`;
    return "";
};

const useLegacyCommissionFormatter = (): CpaFormatter =>
    useDefaultUntilFirstRender(naiveFormatter, cpaToString);

export const useCommissionFormatter = (): CommissionFormatter =>
    useDefaultUntilFirstRender(naiveFormatter, commissionToString);

export default useLegacyCommissionFormatter;
